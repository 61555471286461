<table
  mat-table
  #table
  class="table-alpha"
  [dataSource]="services"
  *ngIf="services"
  matSort
  (matSortChange)="sortChange.emit($event)"
  [matSortActive]="sortActive"
  [matSortDirection]="sortDirection"
  matSortDisableClear
  [multiTemplateDataRows]="includeNotes"
>
  <ng-container matColumnDef="selection">
    <th
      mat-header-cell
      *matHeaderCellDef
      (click)="toggleAll($event)"
      mat-sort-header
      [disabled]="!sortable"
    >
      <mat-checkbox [checked]="isAllSelected"></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row" (click)="selectRow(row, $event)">
      <mat-checkbox [checked]="selectedRows[row.id]"></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="dateString">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [disabled]="!sortable"
    >
      Date
    </th>
    <td mat-cell *matCellDef="let row" class="no-wrap">
      {{ row.dateString | date: 'MMM dd yyyy' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="client">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [disabled]="!sortable"
    >
      Client
    </th>
    <td
      mat-cell
      *matCellDef="let row"
      [routerLink]="['../', 'clients', row.clientId]"
      class="linky"
    >
      {{ row.clientId | client }}
    </td>
  </ng-container>

  <ng-container matColumnDef="staff">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [disabled]="!sortable"
    >
      Staff
    </th>
    <td mat-cell *matCellDef="let row">
      {{ row.userId | user }}
    </td>
  </ng-container>

  <ng-container matColumnDef="minutes">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [disabled]="!sortable"
    >
      Time
    </th>
    <td mat-cell *matCellDef="let row">{{ row.minutes }}m</td>
  </ng-container>

  <ng-container matColumnDef="location">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [disabled]="!sortable"
    >
      Location
    </th>
    <td mat-cell *matCellDef="let row">{{ row.location }}</td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [disabled]="!sortable"
    >
      Category
    </th>
    <td mat-cell *matCellDef="let row">{{ row.category }}</td>
  </ng-container>

  <ng-container matColumnDef="billing">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [disabled]="!sortable"
    >
      Billing
    </th>
    <td mat-cell *matCellDef="let row">
      {{ row.clientInsuranceProviderId | clientInsuranceProvider }}
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row">
      <button mat-icon-button>
        <mat-icon>{{ row.billingId ? 'lock' : 'edit' }}</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="units">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [disabled]="!sortable"
    >
      Units <app-unit-info></app-unit-info>
    </th>
    <td mat-cell *matCellDef="let row">
      {{ row.units }}
    </td>
  </ng-container>

  <ng-container matColumnDef="note">
    <td mat-cell *matCellDef="let row" [attr.colspan]="columns.length">
      {{ row.notes }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr
    mat-row
    class="pointer"
    (click)="edit(row)"
    *matRowDef="let row; columns: columns"
  ></tr>
  <ng-container *ngIf="includeNotes">
    <tr
      mat-row
      class="pointer"
      (click)="edit(row)"
      *matRowDef="let row; columns: ['note']"
    ></tr>
  </ng-container>
</table>
<app-no-results *ngIf="!services?.length"></app-no-results>
